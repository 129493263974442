export const ADMIN_PERMISSIONS = [
  "home",
  "site",
  "attachments",
  "device-monitoring",
  // "device",
  "alarm",
  "user",
  "tenant",
  "license",
  "telemetry",
  "analytics",
  "manage_location",
  "manage_site",
  "manage_support_emails",
  "gateway",
  "profile",
];

export const TENANT_PERMISSIONS = [
  "home",
  "site",
  "attachments",
  "device-monitoring",
  // "device",
  "alarm",
  "user",
  "tenant",
  "license",
  "telemetry",
  "manage_location",
  "manage_site",
  "manage_support_emails",
  "gateway",
  "profile",
];

export const OPERATOR_PERMISSIONS = [
  "home",
  "site",
  "alarm",
  "device-monitoring",
  // "device",
  "telemetry",
  "analytics",
  "attachments",
  "gateway",
  "profile",
];
