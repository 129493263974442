import { useEffect, useContext } from "react";
import { useIntl } from "react-intl";
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";
import { NotificationContext } from "../../../providers/NotificationsProvider";

const MODELS_RESPONSE = gql`
  fragment MODELS_RESPONSE on ForecastModelWithLatestTaskStatusResponse {
    id
    name
    parameterName
    status
    trainingStart
    trainingEnd
    samplingFrequency
    predictionHorizon
    lookbackPeriod
    autotune
    latestTaskStatus
    device {
      id
      name
      type
    }
  }
`;

const FORECAST_MODELS = gql`
  ${MODELS_RESPONSE}
  query forecastModels(
    $tenantId: ID!
    $page: PageInput
    $sort: [String]
    $latestTaskStatus: [TaskStatus]
    $startTime: Long
    $endTime: Long
    $name: String
  ) {
    forecastModels(
      tenantId: $tenantId
      page: $page
      sort: $sort
      latestTaskStatus: $latestTaskStatus
      startTime: $startTime
      endTime: $endTime
      name: $name
    ) {
      page {
        size
        number
        totalPages
        totalElements
      }
      models {
        ...MODELS_RESPONSE
      }
    }
  }
`;

const FORECAST_MODEL_BY_ID = gql`
  ${MODELS_RESPONSE}
  query forecastModelById($tenantId: ID!, $id: ID!) {
    forecastModelById(tenantId: $tenantId, id: $id) {
      ...MODELS_RESPONSE
    }
  }
`;

const ADD_FORECAST_MODEL = gql`
  mutation addForecastModel($input: AddForecastModelInput!) {
    addForecastModel(input: $input) {
      model {
        id
        name
      }
    }
  }
`;

const TRAIN_FORECAST_MODEL = gql`
  mutation trainForecastModel($input: TrainForecastModelInput!) {
    trainForecastModel(input: $input) {
      task {
        id
      }
    }
  }
`;

const PREDICT_FORECAST_MODEL = gql`
  mutation predictForecastModel($input: PredictForecastModelInput!) {
    predictForecastModel(input: $input) {
      task {
        id
      }
    }
  }
`;

const TASKS = gql`
  query tasks(
    $tenantId: ID!
    $page: PageInput
    $sort: [String]
    $filter: [String]
  ) {
    tasks(tenantId: $tenantId, page: $page, sort: $sort, filter: $filter) {
      page {
        size
        number
        totalPages
        totalElements
      }
      tasks {
        id
        name
        type
        status
        updatedOn
      }
    }
  }
`;

const TASK_RESULTS = gql`
  query taskResults($tenantId: ID!, $taskId: ID!) {
    taskResults(tenantId: $tenantId, taskId: $taskId) {
      value
      time
    }
  }
`;

const useAnalytics = () => {
  const { uri }: any = useContext(graphQLContext);
  const { openNotification }: any = useContext(NotificationContext);
  const intl = useIntl();

  const [
    fetchForecastModels,
    {
      loading: isfetchForecastModelsLoading,
      error: fetchForecastModelsError,
      data: fetchForecastModelsData,
    },
  ] = useLazyQuery(FORECAST_MODELS, {
    fetchPolicy: "no-cache",
  });

  const [
    fetchForecastModelById,
    {
      loading: fetchForecastModelByIdLoading,
      error: fetchForecastModelByIdError,
      data: fetchForecastModelByIdData,
    },
  ] = useLazyQuery(FORECAST_MODEL_BY_ID, {
    fetchPolicy: "no-cache",
  });

  const [
    addForecastModel,
    {
      loading: addForecastModelLoading,
      error: addForecastModelError,
      data: addForecastModelData,
    },
  ] = useMutation(ADD_FORECAST_MODEL, {
    refetchQueries: [FORECAST_MODELS],
  });

  const [
    trainForecastModel,
    {
      loading: trainForecastModelLoading,
      error: trainForecastModelError,
      data: trainForecastModelData,
    },
  ] = useMutation(TRAIN_FORECAST_MODEL, {
    refetchQueries: [FORECAST_MODELS],
  });

  const [
    predictForecastModel,
    {
      loading: predictForecastModelLoading,
      error: predictForecastModelError,
      data: predictForecastModelData,
    },
  ] = useMutation(PREDICT_FORECAST_MODEL);

  const [
    fetchTasks,
    {
      loading: fetchTasksLoading,
      error: fetchTasksError,
      data: fetchTasksData,
    },
  ] = useLazyQuery(TASKS);

  const [
    fetchTaskResults,
    {
      loading: fetchTaskResultsLoading,
      error: fetchTaskResultsError,
      data: fetchTaskResultsData,
    },
  ] = useLazyQuery(TASK_RESULTS);

  useEffect(() => {
    if (
      fetchForecastModelsError ||
      addForecastModelError ||
      trainForecastModelError ||
      predictForecastModelError ||
      fetchTasksError ||
      fetchTaskResultsError ||
      fetchForecastModelByIdError
    ) {
      const error =
        fetchForecastModelsError ||
        addForecastModelError ||
        trainForecastModelError ||
        predictForecastModelError ||
        fetchTasksError ||
        fetchTaskResultsError ||
        fetchForecastModelByIdError;
      handleError(error, uri, openNotification, intl);
    }
  }, [
    fetchForecastModelsError,
    addForecastModelError,
    trainForecastModelError,
    predictForecastModelError,
    fetchTasksError,
    fetchTaskResultsError,
    fetchForecastModelByIdError,
  ]);
  return {
    fetchForecastModels,
    isfetchForecastModelsLoading,
    fetchForecastModelsError,
    fetchForecastModelsData,
    fetchForecastModelById,
    fetchForecastModelByIdLoading,
    fetchForecastModelByIdError,
    fetchForecastModelByIdData,
    addForecastModel,
    addForecastModelLoading,
    addForecastModelError,
    addForecastModelData,
    trainForecastModel,
    trainForecastModelLoading,
    trainForecastModelError,
    trainForecastModelData,
    predictForecastModel,
    predictForecastModelLoading,
    predictForecastModelError,
    predictForecastModelData,
    fetchTasks,
    fetchTasksLoading,
    fetchTasksError,
    fetchTasksData,
    fetchTaskResults,
    fetchTaskResultsLoading,
    fetchTaskResultsError,
    fetchTaskResultsData,
  };
};

export default useAnalytics;
