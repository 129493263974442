import common_messages from "./common/de_CH";
// import error_messages from "./error/de_CH";
import navbar_messages from "./navbar/de_CH";
import home_messages from "./home/de_CH";
// import alarm_messages from "./alarm/de_CH";
// import sites_messages from "./sites/de_CH";
// import device_monitoring_messages from "./device-monitoring/de_CH";
import user_messages from "./user/de_CH";
// import tenant_messages from "./tenant/de_CH";
// import telemetry_messages from "./telemetry/de_CH";
// import analytics_messages from "./analytics/de_CH";
// import gateway_messages from "./gateway/de_CH";
import location_messages from "./location/de_CH";
import keys from "./keys/de_CH";
const messages = {
  ...common_messages,
  // ...error_messages,
  ...navbar_messages,
  ...home_messages,
  // ...alarm_messages,
  // ...sites_messages,
  // ...device_monitoring_messages,
  ...user_messages,
  // ...tenant_messages,
  // ...telemetry_messages,
  // ...analytics_messages,
  // ...gateway_messages,
  ...location_messages,
  ...keys,
};

export default messages;
