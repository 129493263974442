const messages = {
  alarms: "Alarms",
  alarm_id: "ID",
  alarm_device: "Device",
  alarm_site: "Site",
  alarm_level: "Level",
  alarm_attribute: "Attribute",
  alarm_status: "Status",
  alarm_description: "Description",
  alarm_assigned_to: "Assigned To",
  alarm_last_updated_by: "Last Updated By",
  alarm_last_updated_time: "Last Updated Time",
  alarm_active: "Active",
  alarm_inprogress: "In Progress",
  alarm_escalated: "Escalated",
  alarm_resolved: "Resolved",
  alarm_deactivated: "Deactivated",
  alarm_date: "Date",
  alarms_sortby: "Sort By",
  alarm_site_placeholder: "Select Site",
  alarm_device_placeholder: "Select Device Type",
  alarm_level_placeholder: "Alarm Levels",
};

export default messages;
