const messages = {
  // Common headers
  id: "ID",
  status: "Status",

  // // Device
  // model: "Model",
  // add_device: "Add Device",
  // device_name: "Device Name",
  // description: "Description",

  // // Location
  // location: "Location",
  // site: "Site",

  // Buttons
  // edit: "Edit",
  // delete: "Delete",
  // save: "Save",
  // update: "Update",
  cancel: "Cancel",
  export: "Export",
  filter: "Filter",
  apply: "Apply",
  reset: "Reset",
  save_changes: "Save Changes",

  // // Common headers
  // settings: "Settings",
  // detail: "Detail",
  // details: "Details",
  // alert: "Alert",
  // id: "ID",
  // actions: "Actions",

  // // Table Headers
  // last_updated_by: "Last Updated By",
  // last_updated_time: "Last Updated Time",

  // // Confirm messages
  // confirm_delete: "Are you sure you want to delete?",
  confirm_deactivate: "Are you sure you want to deactivate?",

  // // Success messages
  // success_del: "Successfully deleted",
  // device_restart_success: "Restart command has been sent",
  // update_setting_success:
  //   "Successfully updated your settings. Settings might take some time to be reflected.",
  // login_success: "Successfully logged in",
  // update_user_password_success: "Successfully changed your password.",

  // User Input
  // all: "All",
  // none: "None",
  // select_site: "Select Site",
  // select_period: "Select Period",
  sort_by: "Sort By",
  required_msg: "This field is required.",

  // // Text input
  // max_char: "Maximum characters",

  // Input Placeholders
  enter_first_name: "Enter First Name",
  enter_last_name: "Enter Last Name",
  enter_email: "Enter Email",
  select_role: "Select Role",
  enter_company: "Enter Company",
  enter_phone_number: "Enter Phone Number",

  // // BreadCrumbs
  // Home: "Home",
  // Device: "Devices",
  // Alarm: "Alarms",
  // User: "Users",
  // Tenants: "Tenants",
  // Telemetry: "Telemetries",

  // // Others
  // not_available: "Not Available",
  // date: "Date",
  // remove: "Remove",
  // search: "Search",
  rows_per_page: "Rows per page",

  // // Severities
  // information: "Information",
  // warning: "Warning",
  // critical: "Critical",

  // Status
  ACTIVE: "Active",
  DEACTIVATED: "Deactivated",
  // acknowledged: "Acknowledged",
  // escalated: "Escalated",
  // in_progress: "In progress",
  // resolved: "Resolved",

  // TenantInfo
  tenant_information: "Tenant Information",
  tenant_id: "Tenant ID",
  tenant_name: "Tenant Name",

  // // Date Range input
  // date_range_label: "Start Date - End Date",

  // // Dates
  // start_date: "Start Date",
  // end_date: "End Date",

  // Dark Mode
  dark_mode: "Dark Mode",
  dark_mode_auto: "Automatic ( follow OS setting )",

  //timeperiod
  hour: "Hour",
  day: "Day",
  week: "Week",

  // //email input
  // support_email_addresses: "Enter Support Email Addresses",
  // enter_email: "Enter Email",
  // manage_support_email_btn: "Manage Support Email",

  // Others
  loading: "Loading",
};

export default messages;
