import { Button, Divider, Popover, Space } from "antd";
import { MoreIcon } from "../../icons";

export type MoreButtonItemProps = {
  label?: string;
  onClick?: Function;
  danger?: Boolean;
};

const MoreButton = (props) => {
  const {
    items,
    placement = "bottomRight",
    trigger = "click",
    customContent = null,
  } = props;

  const content = () => {
    // customContent to override the menu, you can put in whatever you want in the popover
    if (customContent) return customContent;
    return (
      <Space direction="vertical" style={{ minWidth: "168px", gap: "0px" }}>
        {items.map((item, index) => {
          if (item.label === "divider") return <Divider key={index} />;
          else
            return (
              <Button
                key={index}
                type="text"
                danger={item.danger}
                onClick={item.onClick}
                style={{
                  width: "100%",
                  justifyContent: "start",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                  padding: "16px",
                  minHeight: "52px",
                }}
              >
                {item.label}
              </Button>
            );
        })}
      </Space>
    );
  };

  return (
    <Popover
      content={content}
      trigger={trigger}
      placement={placement}
      arrow={false}
      overlayInnerStyle={{ padding: "0px" }}
    >
      <Button
        icon={<MoreIcon width={24} height={24} />}
        style={{ border: "none", boxShadow: "none", background: "none" }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      />
    </Popover>
  );
};

export default MoreButton;
